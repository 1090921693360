export const categoryService = {
    getAll,
    remove,
    create,
    update,
    addSubcategoryToCategory,
    getAllSubcategory
};


// get all category 
function getAll() {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${process.env.REACT_APP_API_URL}/category/getAll`, requestOptions).then(handleResponse);
}
function getAllSubcategory() {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${process.env.REACT_APP_API_URL}/category/subcategory/getAll`, requestOptions).then(handleResponse);
}
function addSubcategoryToCategory(object){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: object
    };

    return fetch(`${process.env.REACT_APP_API_URL}/category/subcategory/add`, requestOptions).then(handleResponse);
 
}
// handle response
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
// delete  
function remove(category_id) {
    const requestOptions = {
        method: 'delete',
    }

    return fetch(
        `${process.env.REACT_APP_API_URL}/category/delete/${category_id}`,
        requestOptions
    ).then(handleResponse)
}



// add category 
function create(object) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: object
    };

    return fetch(`${process.env.REACT_APP_API_URL}/category/create`, requestOptions).then(handleResponse);
}

// update category 
function update(id, object) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: object
    };

    return fetch(`${process.env.REACT_APP_API_URL}/category/update/${id}`, requestOptions).then(handleResponse);
}
