
import React, { Component } from 'react';
//import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
    Box, Container, Dialog, DialogActions, DialogContent,
    DialogTitle, TextField, Button, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import MaterialTable from "material-table";
import { categoryService } from '../../../_services';
import JoditEditor from "jodit-react";
import IntlMessages from 'util/IntlMessages';
import { SmallTitleBar } from 'components/GlobalComponents';
class Categories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updateBtn: false,
            dialog: false,
            title: '',
            description: '',
            errors: {},
            subcategories: [],
            subcategory: "",
            selectedColors: [],
            selectedCategoryId: '',
            addingNewSubcategory: false,
            columnDefs: [
                {
                    title: 'Catégories',
                    field: 'title',
                    width: 175,

                },
                {
                    title: 'Description',
                    render: (rowData) => (<div dangerouslySetInnerHTML={{ __html: rowData.description }}></div>),
                    width: 175,
                },
                {
                    title: 'Sous Catégories',
                    render: (rowData) => (
                        <ul>
                            {rowData.subcategories.map((subcategory) => (
                                <li key={subcategory._id}>{subcategory.title}</li>
                            ))}
                        </ul>
                    ),
                    width: 175,

                },

            ],
            rowData: [],
        };


    }
    componentDidMount() {
        this.getCategories()
    }
    handleColorChange = (event) => {
        // Getting selected options
        const selectedColors = event.target.value;
        // Updating state with the selected options
        this.setState({ selectedColors });
        console.log(this.state.selectedColors)

    }
    handleSubcategoryChange = event => {
        const value = event.target.value;
        if (value === "new") {
            // Show input field for adding new subcategory
            this.setState({ dialog: true, subcategory: "", addingNewSubcategory: true, selectedColors: [] });
        } else {
            this.setState({ subcategory: value, addingNewSubcategory: false });
        }
    };

    handleNewSubcategoryChange = event => {
        const value = event.target.value;
        this.setState({ subcategory: value });
    };

    getCategories() {
        categoryService.getAll()
            .then(
                result => {
                    this.setState({ rowData: result.categories });
                },
                error => {
                    console.log('error', error)
                }
            );

    }


    deleteCategory(rowData) {
        categoryService.remove(rowData._id).then(
            (result) => {
                this.getCategories()
            },
            (error) => {
                console.log('error', error)
            }
        )
    }

    // on change value of input
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onChangeDescription = description => {
        this.setState({ description: description });
    }



    // add product
    addProduct() {
        if (this.validate()) {
            let categoryObject = this.initCategoryObject();
            let subcategoryObjectString = this.initSubcategoryObject();
            let subcategoryObject = JSON.parse(subcategoryObjectString);
    console.log(subcategoryObject)
            categoryService.create(categoryObject)  // Create the category
                .then(categoryResult => {
                    const categoryId = categoryResult.category._id;
                
                    // Update subcategory object with the created category ID
                    subcategoryObject.categoryId = categoryId;
    
    
                    let updatedSubcategoryObjectString = JSON.stringify(subcategoryObject);

                    // Create the subcategory and update the category with the new subcategory
                    categoryService.addSubcategoryToCategory(updatedSubcategoryObjectString)
                        .then(() => {
                            this.setState({ dialog: false });
                            this.getCategories();
                        })
                        .catch(error => {
                            // Handle errors if any
                            console.error("Error adding subcategory:", error);
                        });
                })
                .catch(error => {
                    // Handle errors if any
                    console.error("Error adding category:", error);
                });
        }
    }
    
    openEditModal(data) {
        this.setState({ updateBtn: true });
        this.setState({
            id: data._id,
            title: data.title,
            description: data.description,
            subcategories: data.subcategories
        })
        this.setState({ dialog: true })
    };
    openAddModal() {
        this.setState({
            dialog: true,
            updateBtn: false,
            title: '',
            description: '',
            selectedColors:[],
            category:''
        })
    }

    // update product
    updateProduct() {
        if (this.validate()) {
            let object = this.initCategoryObject();
            let objectSubcategory = this.initSubcategoryObject();

            categoryService.update(this.state.id, object)
                .then(() => categoryService.addSubcategoryToCategory(objectSubcategory))
                .then(() => {
                    this.setState({ dialog: false });
                    this.getCategories();
                })
                .catch(error => {
                    // Handle errors if any
                    console.error("Error updating product:", error);
                });
        }
    }


    // init category object
    initCategoryObject() {
        let object = JSON.stringify({
            title: this.state.title,
            description: this.state.description,

        })
        return (object)
    }
    initSubcategoryObject() {
        let object = JSON.stringify({
            subcategoryData: {
                title: this.state.subcategory,
                description:this.state.subcategory,
                colors: this.state.selectedColors,
                options: []
            },
            categoryId:this.state.id? this.state.id:""

        })
        return (object)
    }

    // form validation
    validate() {
        let isValid = true
        let errors = {};
        if (!this.state.title) {
            errors["title"] = "Veuillez saisir le titre.";
            isValid = false
        }
        this.setState({
            errors: errors
        });
        return isValid
    }

    render() {
        const { addingNewSubcategory, selectedColors } = this.state;
        return (
            <div className="tables-wrapper">
                <SmallTitleBar title={<IntlMessages id="sidebar.categories" />} />
                <Container maxWidth='lg'>
                    <Box px={{ xs: '12px', lg: 0 }} className='page-space-top'>

                        <div
                            style={{ height: '100%', width: '100%' }}
                            className='ag-fresh'>
                            <MaterialTable
                                localization={{
                                    header: { actions: 'action' }, toolbar: { exportName: 'Exporter au format CSV', exportTitle: "Exporter les données de l'entreprise", searchPlaceholder: 'recherche' }, body: { emptyDataSourceMessage: 'Aucun enregistrement à afficher' }, pagination: {
                                        pagination: {
                                            labelDisplayedRows: '{de} de {à} ',
                                            labelRowsSelect: 'lignes',
                                            showColumnsTitle: 'Afficher les colonnes',
                                            showColumnsAriaLabel: 'Afficher les colonnes',
                                            labelRowsPerPage: 'Lignes par page :',
                                            firstAriaLabel: 'Première page',
                                            firstTooltip: 'Première page',
                                            previousAriaLabel: 'Page précédente',
                                            previousTooltip: 'Page précédente',
                                            nextAriaLabel: 'Page suivante',
                                            nextTooltip: 'Page suivante',
                                            lastAriaLabel: 'Dernière page',
                                            lastTooltip: 'Dernière page'
                                        },
                                    },
                                }}

                                title={<IntlMessages id="sidebar.categories" />}

                                columns={this.state.columnDefs}
                                data={this.state.rowData}
                                actions={[
                                    {
                                        icon: 'add',
                                        tooltip: 'ajouter une catégorie',
                                        isFreeAction: true,
                                        onClick: () => { this.openAddModal() }

                                    },
                                    rowData => ({
                                        icon: 'edit',
                                        tooltip: 'actualiser une catégorie',
                                        onClick: (event, rowData) => { this.openEditModal(rowData) }
                                    }),
                                    rowData => ({
                                        disabled: rowData._id === '623af8dc9db29f7dfbce8be6' || rowData._id === "6245738371255a502ad4b516",

                                        icon: 'delete',
                                        tooltip: 'supprimer une catégorie',
                                        iconProps: { color: "primary", className: "material-icons-outlined" },
                                        onClick: (event, rowData) =>
                                            this.deleteCategory(rowData),
                                    }),
                                ]
                                }
                                options={{
                                    actionsColumnIndex: -1,
                                    search: true,
                                }} />
                        </div>

                    </Box>
                </Container>
                {/* Add - edit product dialog  */}
                <Dialog
                    open={this.state.dialog}
                    onClose={() => this.setState({ dialog: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle className="dialog-header" id="alert-dialog-title">{"New categories"}</DialogTitle>
                    <DialogContent className="dialog-content">
                        <TextField margin="dense" id="title" name="title" value={this.state.title} label="Titre" type="text" fullWidth onChange={this.onChange} />
                        <div className="text-danger">{this.state.errors.title}</div>
                        <br />
                        <label>Description</label>
                        <JoditEditor
                            name="description"
                            placeholder="veuillez entrer un texte"
                            value={this.state.description}
                            onChange={this.onChangeDescription}
                        />

                        <FormControl fullWidth>
                            <InputLabel id="subcategory-select-label">Sous-catégorie</InputLabel>
                            <Select
                                labelId="subcategory-select-label"
                                id="subcategory-select"
                                value={this.state.subcategory}
                                onChange={this.handleSubcategoryChange}
                            >
                                {/* Option pour ajouter une nouvelle sous-catégorie */}
                                <MenuItem value="new">Ajouter une nouvelle sous-catégorie</MenuItem>
                                {/* Options pour les sous-catégories existantes */}
                                {this.state.subcategories && this.state.subcategories.length > 0 && this.state.subcategories.map(subcategory => (
                                    <MenuItem key={subcategory._id} value={subcategory._id}>{subcategory.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* Input field for adding new subcategory */}
                        {addingNewSubcategory && (
                            <>
                                <TextField
                                    margin="dense"
                                    id="new-subcategory"
                                    name="subcategory"
                                    label="Nouvelle sous-catégorie"
                                    type="text"
                                    fullWidth
                                    onChange={this.handleNewSubcategoryChange}
                                />
                                <FormControl fullWidth>
                                    <InputLabel id="select-multiple-colors-label">Tous les couleurs</InputLabel>

                                    <Select
                                        labelId="select-multiple-colors-label"
                                        id="select-multiple-colors"
                                        multiple={true}
                                        value={selectedColors}
                                        onChange={this.handleColorChange}
                                        fullWidth
                                    >
                                        <MenuItem value='rouge'>rouge</MenuItem>
                                        <MenuItem value='bleu'>bleu</MenuItem>
                                        <MenuItem value='vert'>vert</MenuItem>
                                        <MenuItem value='noir'>noir</MenuItem>
                                        <MenuItem value='blanc'>blanc</MenuItem>

                                        {/* Add more colors as needed */}
                                    </Select>
                                </FormControl>
                            </>
                        )}


                    </DialogContent>
                    <DialogActions className="dialog-footer">
                        <Button className="thm-btn" onClick={() => this.setState({ dialog: false })}>
                            Annuler
                        </Button>
                        {
                            this.state.updateBtn ?
                                <Button onClick={() => this.updateProduct()} variant="outlined" className="thm2-btn" autoFocus>
                                    mettre à jour
                                </Button>
                                :
                                <Button onClick={() => this.addProduct()} variant="outlined" className="thm2-btn" autoFocus>
                                    Ajouter
                                </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div >
        )
    }
}
export default Categories