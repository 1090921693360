export default {
    "sidebar.dashboard": "Tableau de bord",
    "sidebar.package": "Paquet",
    "sidebar.notifications": "Journal",
    "sidebar.statistics": "Statistiques",
    "sidebar.developers": "Développeurs",
    "sidebar.apiKeys": "Clés API",
    "sidebar.documentation": "Documentation",
    "sidebar.search": "Rechercher",
    "sidebar.customers": "Clients",
    "sidebar.products": "Articles et produits",
    "sidebar.categories": "Catégories",
    "sidebar.invoices": "Factures",
    "sidebar.users": "Liste des utilisateurs",
    "sidebar.companies": "Entreprise",
    "sidebar.companiesList": "Liste des entreprises",
    "widgets.api_log": "JOURNAL API SH",
    "sidebar.api_log": "JOURNAL API",
    "sidebar.export": "Exporter",
    "sidebar.crm_settings": "Paramètres CRM",
    "sidebar.cms_link": "Gérer le site Web",
    "sidebar.persons": "Recherche de personnes",
     "sidebar.personsList": "Liste de recherche",
     "sidebar.shop": "Boutique",
     "widgets.welcome": "Bienvenue",
     "widgets.titleError": "Veuillez saisir le titre.",
     "widgets.priceNetError": "Veuillez saisir le prix (net).",
     "widgets.categoryError": "Veuillez entrer la catégorie.",
     "widgets.products": "Produits",
     "widgets.company_list": "Liste des entreprises",
     "widgets.title": "Titre",
     "widgets.category": "Catégorie",
     "widgets.productDescription": "Description du produit",
     "widgets.image": "Image",
     "widgets.price_net": "Prix (net)",
     "widgets.price_brut": "Prix brut",
     "widgets.tva": "UST.",
     "widgets.discount": "Remise",
     "widgets.submit": "Enregistrer",
     "widgets.annuler": "Annuler",
     "widgets.companies": "Entreprise",
     "widgets.invoice": "Factures",
     "widgets.invoices": "Factures",
     "widgets.users": "Utilisateurs",
     "widgets.BankData": "BankData",
     "widgets.Api": "API",
     "widgets.search": "Rechercher...",
     "widgets.company": "Entreprise",
     "widgets.manage_your_account": "Gérer votre compte",
     "widgets.sign_out": "Déconnexion",
     "widgets.date": "Date",
     "widgets.status": "Statut",
     "widgets.requests": "Requête",
     "widgets.ContactPerson": "Personne à contacter",
     "widgets.customerList": "LISTE CLIENTS",
     "widgets.customers": "Clients",
     "widgets.customerDetails": "Détails du client",
     "component.email": "E-mail",
     "component.firstName": "Prénom",
     "component.lastName": "Nom de famille",
     "component.salutation": "Salut",
     "component.title": "Titre",
     "customer": "Client",
   
     "component.general": "Général",
     "component.account": "Compte",
     "component.password": "Mot de passe",
     "component.update": "Mise à jour",
     "component.settings": "Paramètres",
     "component.newPassword": "Nouveau mot de passe",
     "component.retypePassword": "Confirmer le mot de passe",
     "component.passwordError": "Votre mot de passe doit comporter au moins 6 caractères",
     "component.passwordMatchError": "Veuillez confirmer votre mot de passe",
     "component.invoice": "Commandes",
     "component.addUser": "Ajouter un utilisateur",
     "component.edit": "modifier",
     "component.delete": "Supprimer",
     "component.logo": "Logo",
     "component.company_name": "Nom de la société",
     "component.phone_number": "Téléphone fixe",
     "component.mobile_number": "Mobile",
     "component.fax": "Fax",
     "component.house_number": "Numéro de maison",
     "component.street": "Rue",
     "component.zip_code": "Code postal",
     "component.city": "Ville/Emplacement",
     "component.country": "Pays",
     "component.bank_name": "Nom de la banque",
     "component.bank_account": "Numéro de compte",
     "component.iban": "IBAN",
     "component.bic": "BIC",
     "component.submit": "Enregistrer",
     "component.username": "Nom d'utilisateur",
     "component.key": "Clé API",
     "component.invoice_lower_text": "Texte de la facture (sous la facture)",
     "component.invoice_upper_text": "Texte de la facture (au-dessus de la facture)",
     "component.tva": "UST.",
     "component.website": "Site Web",
     "component.customer": "Client",
     "component.deleteCustomeralert": "Êtes-vous sûr de vouloir supprimer définitivement ce client ?",
     "component.deleteUseralert": "Etes-vous sûr de vouloir supprimer définitivement cet utilisateur ?",
     "component.contactInformation": "CONTACT",
     "component.deleteMessage": "Êtes-vous sûr de vouloir supprimer ceci définitivement ?",
     "component.address": "ADRESSE",
     "component.yes": "Oui",
     "component.no": "Non",
     "component.ustid": "Numéro d'identification UST.",
     "component.registernumber": "Numéro de registre",
     "component.invoiceAddress": "Autre adresse de facturation",
     "component.paymentMethod": "Mode de paiement",
     "component.paymentMethodText": "Procédure de prélèvement SEPA",
     "component.products_and_prices": "Produits et prix",
     "component.add": "Ajouter",
     "component.sender_number": "Expéditeur(numéro)",
     "component.sendInvoiceMessage": "Voulez-vous envoyer une facture par e-mail ?",
     "component.save_draft": "Brouillon",
     "widgets.updateCustomer": "MODIFIER",
     "widgets.orders": "Total des commandes",
     "widgets.new_customers": "Nouveaux clients",
     "widgets.document_order": "Commandes totales : documents",
     "widgets.person_order": "Commandes totales : personnes",
     "widgets.number_of_customers": "Nombre de clients",
     "widgets.number_of_guest": "Nombre d'achats d'invités",
     "widgets.document_order_number": "Nombre de commandes : documents",
     "widgets.person_order_number": "Nombre de commandes : personnes",
     "widgets.unity": "Unités de mesure",
     "widgets.daily_statistics": "Statistiques quotidiennes"


}