export const productService = {
   getAll,
   remove,
   create,
   update,
   
};




// get all product 
function getAll() {
   const requestOptions = {
      method: 'GET'
   };

   return fetch(`${process.env.REACT_APP_API_URL}/products/getAll`, requestOptions).then(handleResponse);
}
// handle response
function handleResponse(response) {
   return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
         const error = (data && data.message) || response.statusText;
         return Promise.reject(error);
      }
      return data;
   });
}
// delete product 
function remove(product_id) {
   const requestOptions = {
      method: 'delete',
   }

   return fetch(
      `${process.env.REACT_APP_API_URL}/products/delete/${product_id}`,
      requestOptions
   ).then(handleResponse)
}



// add product 
function create(object) {
   const requestOptions = {
      method: 'POST',
      body: object
   };
   console.log(object,'object')

   return fetch(`${process.env.REACT_APP_API_URL}/products/create`, requestOptions).then(handleResponse);
}

// update product 
function update(id, object) {
   const requestOptions = {
      method: 'PUT',
      body: object
   };

   return fetch(`${process.env.REACT_APP_API_URL}/products/update/${id}`, requestOptions).then(handleResponse);
}
