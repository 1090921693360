/**
 * Blog Routing File
 */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  ProductsComponent
} from 'routes'

const Customers = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/`}
      component={ProductsComponent}
    ></Route>
   
  </Switch>
)
export default Customers
