/**
 * Router service file
*/
import Dashboard from 'routes/Dashboard'

// Async component
import {
   AsyncUserSettingsComponent,
   AsyncCrmSettingsComponent
} from 'routes';
import Customers from 'routes/Customers';
import Products from 'routes/Products';
import Categories from 'routes/Categories/Categories';
import Invoices from 'routes/invoices';
import Users from 'routes/Users';
export default [
   {
      path: 'dashboard',
      component: Dashboard
   },
   {
      path: 'customer',
      component: Customers
   },
   {
      path: 'users',
      component: Users
   },
   {
      path: 'user-settings',
      component: AsyncUserSettingsComponent
   },


   {
      path: 'settings',
      component: AsyncCrmSettingsComponent
   },
   {
      path: 'products',
      component: Products
   },
   {
      path: 'categories',
      component: Categories
   },
   {
      path: 'invoices',
      component: Invoices

   },
]